<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <router-link
            :to="{
              name: 'course-module',
              params: { slug: $route.params.slug, module: currentModuleId },
            }"
            class="theme_one_content_container_btn_back"
          >
            <div class="theme_one_content_container_btn_text">
              {{ $t("Back") }}
            </div>
          </router-link>
          <div class="theme_one_plot">
            <div
              v-if="currentModule && currentModule.themes"
              v-for="theme in currentModule.themes"
              class="theme_one_plot_theme"
            >
              <div class="theme_one_plot_theme_active_label">
                {{ theme.name }}
              </div>
            </div>
          </div>

          <div
            class="theme_one_plot"
            v-if="currentModule && currentModule.quizzes"
          >
            <div class="theme_one_plot_theme_active">
              <div class="theme_one_plot_theme_active_label">
                {{ $t("Testing") }}
              </div>
              <router-link
                class="theme_one_plot_theme_item exercise"
                style="text-decoration: none"
                :to="{
                  name: 'course-exercise',
                  params: { slug: $route.params.slug, quiz: quiz.id },
                }"
                v-for="quiz in currentModule.quizzes"
              >
                {{ quiz.title }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="theme_one_exercises_desc">
          <div
            class="theme_one_exercises_desc_text_container"
            v-if="!(error && success)"
          >
            <div class="theme_one_modal_save_result" v-if="success">
              <div class="theme_one_modal_result_text">
                {{ $t("Response_saved_successfully") }}
              </div>
              <div class="theme_one_modal_result_img"></div>
            </div>
            <div
              class="theme_one_modal_result_bad"
              v-if="finished && result < quiz.passing_score"
            >
              <div class="theme_one_modal_bad_text_block">
                <div class="theme_one_modal_bad_result_text w-100">
                  <h5>{{ $t("Sorry_you_did_not_pass_the_test") }}</h5>
                  <br />
                  <h6>
                    {{ $t("Your_score") }}: {{ percent }}%. Вы набрали
                    {{ result }} из {{ max_score }} баллов.
                    <br />
                    <br />
                    Проходной балл {{ passing_score }}
                  </h6>
                </div>
                <div
                  class="theme_one_modal_bad_result_btn pointer"
                  @click="reloadExercise"
                >
                  {{ $t("try_again") }}
                </div>
              </div>
            </div>
            <div
              class="theme_one_modal_result_good"
              v-if="finished && result >= quiz.passing_score"
            >
              <div class="theme_one_modal_result_text">
                {{ $t("Congratulations!_You_have_passed_the_test") }}
                <br /><br />
                {{ $t("Your_score") }}: {{ percent }}%. Вы набрали
                {{ result }} из {{ max_score }} баллов. Проходной балл
                {{ passing_score }}
              </div>
            </div>
            <div class="theme_one_desc_title">
              {{ finished ? "Результаты теста: " : "" }}{{ quiz.title }}
            </div>
            <div class="theme_one_desc_test w-100">
              <div class="theme_one_modal_result_bad" v-if="error">
                <div class="theme_one_modal_bad_text_block">
                  <div class="theme_one_modal_bad_result_text">
                    {{ $t("An_error_has_occurred") }}
                  </div>
                  <div class="theme_one_modal_bad_result_btn">
                    {{ $t("Try_again") }}
                  </div>
                </div>
                <div class="theme_one_modal_result_img"></div>
              </div>

              <div v-else>
                <div
                  v-if="currentQuestion && currentQuestion.text"
                  class="theme_one_desc_text"
                  v-html="currentQuestion.text"
                ></div>
                <div v-if="currentQuestion && currentQuestion.file">
                  <audio
                    v-if="currentQuestion.file.type === 'audio'"
                    :src="apiRoot + currentQuestion.file.path"
                    controls
                  ></audio>
                </div>
                <div
                  v-if="currentQuestion && currentQuestion.file"
                  class="mb-5 w-100 text-center"
                >
                  <a
                    :href="apiRoot + currentQuestion.file.path"
                    v-if="currentQuestion.file.type === 'file'"
                    target="_blank"
                    class="theme_one_desc_download"
                  >
                    {{ $t("Download_file") }}
                  </a>
                </div>
                <div
                  class="my_courses_list_radio_block"
                  v-if="
                    (currentQuestion &&
                      currentQuestion.type === 'TYPE_SINGLE') ||
                    (currentQuestion &&
                      currentQuestion.type === 'TYPE_MULTIPLE')
                  "
                >
                  <div
                    class="my_courses_radio_item"
                    v-if="currentQuestion && currentQuestion.answers"
                    v-for="answer in currentQuestion.answers"
                  >
                    <input
                      v-if="currentQuestion.type === 'TYPE_SINGLE'"
                      v-model="selectedAnswer"
                      :value="answer.id"
                      :id="`answer${answer.id}`"
                      type="radio"
                      name="answer[]"
                      class="my_courses_list_radio"
                    />
                    <input
                      v-if="currentQuestion.type === 'TYPE_MULTIPLE'"
                      v-model="selectedAnswer"
                      :value="answer.id"
                      :id="`answer${answer.id}`"
                      type="checkbox"
                      name="answer[]"
                      class="my_courses_list_radio"
                    />
                    <label
                      :for="`answer${answer.id}`"
                      class="my_courses_radio_label pointer pt-3"
                      v-html="answer.text"
                    ></label>
                  </div>
                </div>
                <div v-else>
                  <textarea
                    class="form-control mb-5"
                    v-model="selectedAnswerRaw"
                  />
                </div>
              </div>
            </div>
            <div class="theme_one_desc_btn" v-if="!finished">
              <!--              <a @click="saveAnswer" class="theme_one_desc_btn_back pointer">{{ $t('Save_answer') }}</a>-->
              <a
                v-if="!firstQuestion"
                @click="prev"
                class="theme_one_desc_btn_back pointer"
                >{{ $t("Back") }}</a
              >
              <a
                v-if="!lastQuestion"
                @click="next"
                class="theme_one_desc_btn_next pointer"
                >{{ $t("Further") }}</a
              >
              <a
                v-if="lastQuestion"
                @click="finish"
                class="theme_one_desc_btn_next pointer"
                >{{ $t("Finish") }}</a
              >
            </div>
          </div>
          <div class="theme_one_exercises_desc_text_container" v-else>
            <div class="theme_one_desc_test w-100" style="height: 400px">
              <div class="theme_one_modal_result_bad">
                <div class="theme_one_modal_bad_text_block">
                  <div class="theme_one_modal_bad_result_text">
                    {{ $t("Data_not_found") }}
                  </div>
                  <div class="theme_one_modal_bad_result_btn">
                    {{ $t("Try_again") }}
                  </div>
                </div>
                <div class="theme_one_modal_result_img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "CourseExercise",
  data() {
    return {
      finished: false,
      results: [],
      result: 0,
      percent: 0,
      passing_score: 0,
      max_score: 0,
      success: false,
      error: false,
      quiz: false,
      apiRoot: window.API_ROOT,
      selectedAnswer: [],
      selectedAnswerRaw: null,
      currentQuestionId: 0,
      course: false,
    };
  },
  methods: {
    getQuiz() {
      let slug = this.$route.params.slug;
      let quiz = this.$route.params.quiz;
      this.$http
        .get(`${this.apiRoot}/api/course/${slug}/quiz/${quiz}`)
        .then((res) => {
          this.quiz = res.body;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    getCourseDetails() {
      let slug = this.$route.params.slug;
      this.$http
        .get(`${this.apiRoot}/api/course/${slug}`)
        .then((res) => {
          this.course = res.body;
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    saveAnswer() {
      let params =
        this.selectedAnswerRaw !== null
          ? {
              answer: this.selectedAnswerRaw,
              answer_raw: this.selectedAnswerRaw,
              question_id: this.currentQuestion.id,
              quiz_id: this.quiz.id,
            }
          : {
              answer: this.selectedAnswer,
              question_id: this.currentQuestion.id,
              quiz_id: this.quiz.id,
            };
      this.$http
        .post(`${this.apiRoot}/api/course/quiz/answer/store`, params)
        .then((res) => {
          // if (res.body.message === 'saved')
          // this.success = true
          // setTimeout(() => {
          //   this.success = false
          // }, 1000)
        })
        .catch((err) => {
          // if (err.body.message === 'not_saved')
          // this.error = true
          // setTimeout(() => {
          //   this.error = false
          // }, 1000)
        })
        .finally(() => {});
    },
    prev() {
      if (!this.currentQuestionId) return;
      if (this.selectedAnswerRaw !== "null" || this.selectedAnswer.length) {
        this.saveAnswer();
      }
      this.currentQuestionId--;
      this.error = false;
      this.success = false;
      this.selectedAnswer = [];
      this.selectedAnswerRaw = null;
    },
    next() {
      if (typeof this.selectedAnswer == "object") {
        if (this.selectedAnswer.length == 0) {
          this.selectedAnswer = "";
        }
      }
      if (this.selectedAnswer || this.selectedAnswerRaw) {
        console.log(this.selectedAnswer);
        this.saveAnswer();
        this.currentQuestionId++;
        this.error = false;
        this.success = false;
        this.selectedAnswer = [];
        this.selectedAnswerRaw = null;
        return;
      }
      Vue.toastr({
        description: "Необходимо выбрать ответ",
        type: "error",
      });
    },
    finish() {
      if (typeof this.selectedAnswer == "object") {
        if (this.selectedAnswer.length == 0) {
          this.selectedAnswer = "";
        }
      }
      if (this.selectedAnswer || this.selectedAnswerRaw) {
        this.saveAnswer();
      } else {
        Vue.toastr({
          description: "Необходимо выбрать ответ",
          type: "error",
        });
        return;
      }
      if (this.quiz.mode === "DEFAULT") {
        this.$http
          .get(
            `${this.apiRoot}/api/course/quiz/get-user-answers/${this.quiz.id}`,
          )
          .then((res) => {
            this.results = res.body.data;
            this.result = res.body.result;
            this.passing_score = res.body.passing_score;
            this.percent = res.body.percent;
            this.max_score = res.body.max_score;
            this.error = false;
            this.success = false;
            this.finished = true;
          })
          .catch((err) => {
            Vue.toastr({
              message: "Error",
              description: "Не удалось завершить тест",
              type: "error",
            });
          })
          .finally(() => {});
      } else {
        this.$router.push({
          name: "course-module",
          params: {
            slug: this.$route.params.slug,
            module: this.currentModuleId,
          },
        });
      }
    },
    reloadExercise() {
      window.location.reload();
    },
  },
  computed: {
    currentQuestion() {
      if (!this.quiz.questions) {
        return;
      }
      return this.quiz.questions.find((question, key) => {
        return key == this.currentQuestionId;
      });
    },
    currentModule: function () {
      if (!(this.course && this.course.modules && this.quiz.owner))
        return false;
      return this.course.modules.find(
        (module) => module.id === this.quiz.owner.id,
      );
    },
    currentModuleId: function () {
      if (!(this.quiz && this.quiz.owner)) return;
      return this.quiz.owner.id;
    },
    firstQuestion: function () {
      return this.currentQuestionId == 0;
    },
    lastQuestion: function () {
      return this.currentQuestionId + 1 === this.quiz.questions.length;
    },
  },
  mounted() {
    this.getCourseDetails();
    this.getQuiz();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getCourseDetails();
    this.getQuiz();
  },
};
</script>

<style scoped></style>
